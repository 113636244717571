const PG_CPV_SECTION_ONE_CONTENT = {
  linkList: [
    { path: "https://wannaflix.com/", action: "wannaflix" },
    { path: "https://www.hotspotshield.com/zh/", action: "hotspotshield" },
    { path: "https://bmkj88.com/auth/register", action: "bmkj88" },
    { path: "https://nord-help.net/zh/download/", action: "nord-help" },
  ],
  downloadButton: {
    path: "https://chrome.google.com/webstore/detail/amzscout-pro/njopapoodmifmcogpingplfphojnfeea?с=china",
    action: "clickToDownload",
  },
}
export default PG_CPV_SECTION_ONE_CONTENT
