import React, { useState } from "react";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import Layout from "../../layouts/LayoutCn";
import Seo from "../../components/Seo";
import SectionOne from "../../components/cn/pro-vpn/SectionOne";

const PgCPV = () => {
  const [isOpacity, setIsOpacity] = useState(true);
  const [isCnUser, setIsCnUser] = useState(false);

  return (
    <Layout
      isCnUser={isCnUser}
      setIsCnUser={setIsCnUser}
      isOpacity={isOpacity}
      setIsOpacity={setIsOpacity}
      isCnPage={false}
      bgHeader="#fff"
    >
      <Seo
        title="如何在使用VPN 的情况下安装AMZ中文 PRO 插件"
        description="本说明将为您提供有关如何使用VPN安装AMZ侦探的PRO插件的所有必要信息。如果您目前没有任何VPN服务，我们将为您提供一些建议，以方便您使用。"
        page="cn/pro-vpn"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <SectionOne />
    </Layout>
  );
};

export default PgCPV;
